import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import LocalImages from "../../utils/localImages";

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <>
      {!isMobile ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              padding: "20px 0",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "50%",
              }}
            >
              <Typography sx={{ color: "#424546", fontSize: "16px" }}>
                © Copyright TradeSphere Group 2020
              </Typography>
              <Typography sx={{ color: "#424546", fontSize: "16px" }}>
                Terms of Use
              </Typography>
              <Typography sx={{ color: "#424546", fontSize: "16px" }}>
                Privacy Policy
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "30%",
              }}
            >
              <Typography sx={{ color: "#424546", fontSize: "16px" }}>
                Contact Us
              </Typography>
              <img
                style={{ width: "20px", height: "20px" }}
                src={LocalImages.Linkedin}
                alt="Linkedin"
              />
              <img
                style={{ width: "20px", height: "20px" }}
                src={LocalImages.Facebook}
                alt="Linkedin"
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
        <Box sx={{width:"70%", ml:"11%", padding:"40px 0"}}>
        <Typography sx={{ color: "#424546", fontSize: "16px",textAlign:"left", mb:"14%" }}>
                © Copyright TradeSphere Group 2020
              </Typography>
              <Typography sx={{ color: "#424546", fontSize: "16px",textAlign:"left", mb:"14%" }}>
                Terms of Use
              </Typography>
              <Typography sx={{ color: "#424546", fontSize: "16px", textAlign:"left", mb:"14%" }}>
                Privacy Policy
              </Typography>
              <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                width: "100%",
              }}
            >
              <Typography sx={{ color: "#424546", fontSize: "16px", textAlign:"left", marginRight:"70px", width:"150px" }}>
                Contact Us
              </Typography>
              <img
                style={{ width: "20px", height: "20px",marginRight:"75px" }}
                src={LocalImages.Linkedin}
                alt="Linkedin"
              />
              <img
                style={{ width: "20px", height: "20px" }}
                src={LocalImages.Facebook}
                alt="Linkedin"
              />
            </Box>
        </Box>
        
        </>
      )}
    </>
  );
};

export default Footer;
