import React from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import LocalImages from "./utils/localImages";
import Banner from "./components/banner";
import Info from "./components/dataInfo";
import About from "./components/about";
import WhyChoose from "./components/whyChooseUs";
import Step from "./components/step";
import How from "./components/howItWorks";
import Steps from "./components/step2";
import Join from "./components/join";
import Contact from "./components/contact";
import Footer from "./components/footer";
import "./App.css";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="App">
      <>
        {!isMobile ? (
          <>
            <Box
              sx={{
                backgroundColor: "#ffffff",
                height: "74px",
                display: "flex",
                justifyContent: "space-between",
                width: "98%",
                alignItems: "center",
                padding: "0px 20px",
                position: "fixed",
                zIndex: "10",
              }}
            >
              <img
                style={{ width: "152px", height: "46px" }}
                src={LocalImages.Logo}
                alt="Logo"
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "530px",
                }}
              >
                <Typography sx={{ color: "#4f4f4f" }}>
                  <a
                    style={{ textDecoration: "none", color: "#4f4f4f" }}
                    href="#about"
                  >
                    ABOUT
                  </a>
                </Typography>
                <Typography sx={{ color: "#4f4f4f" }}>
                  <a
                    style={{ textDecoration: "none", color: "#4f4f4f" }}
                    href="#why"
                  >
                    WHY CHOOSE US
                  </a>
                </Typography>
                <Typography sx={{ color: "#4f4f4f" }}>
                  <a
                    style={{ textDecoration: "none", color: "#4f4f4f" }}
                    href="#how"
                  >
                    HOW IT WORKS
                  </a>
                </Typography>
                <Typography sx={{ color: "#4f4f4f" }}>
                  <a
                    style={{ textDecoration: "none", color: "#4f4f4f" }}
                    href="#contact"
                  >
                    CONTACT
                  </a>
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "170px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tooltip title="This Page is under development" arrow>
                  <Button
                    sx={{
                      color: "#424546",
                      fontSize: "16px",
                      backgroundColor: "transparent",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "#097390",
                        color: "#fff",
                      },
                    }}
                  >
                    LOG IN
                  </Button>
                </Tooltip>
                <Tooltip title="This Page is under development" arrow>
                  <Button
                    sx={{
                      color: "#ffffff",
                      fontSize: "16px",
                      backgroundColor: "#0da4ce",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "#097390",
                        color: "#fff",
                      },
                    }}
                  >
                    SIGN UP
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor:"#ffffff",
                width: "93%",
                height: "68px",
                alignItems: "center",
                padding: "0 15px",
                position:"fixed",
                zIndex:"10",
              }}
            >
              <div
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  style={{ width: "35px", height: "28px" }}
                  src={LocalImages.Ham}
                  alt="Menu"
                />
              </div>

              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <MenuItem>
                  <Typography sx={{ color: "#4f4f4f" }}>
                    <a
                      style={{ textDecoration: "none", color: "#4f4f4f" }}
                      href="#about"
                    >
                      ABOUT
                    </a>
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography sx={{ color: "#4f4f4f" }}>
                    <a
                      style={{ textDecoration: "none", color: "#4f4f4f" }}
                      href="#why"
                    >
                      WHY CHOOSE US
                    </a>
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography sx={{ color: "#4f4f4f" }}>
                    <a
                      style={{ textDecoration: "none", color: "#4f4f4f" }}
                      href="#how"
                    >
                      HOW IT WORKS
                    </a>
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <Typography sx={{ color: "#4f4f4f" }}>
                    <a
                      style={{ textDecoration: "none", color: "#4f4f4f" }}
                      href="#contact"
                    >
                      CONTACT
                    </a>
                  </Typography>
                </MenuItem>
              </Menu>
              <Box
                sx={{
                  width: "170px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  sx={{
                    color: "#424546",
                    fontSize: "14px",
                    height: "42px",
                    backgroundColor: "transparent",
                    borderRadius: "0px",
                    "&:hover": {
                      backgroundColor: "#097390",
                      color: "#fff",
                    },
                  }}
                >
                  LOG IN
                </Button>
                <Button
                  sx={{
                    color: "#ffffff",
                    fontSize: "14px",
                    height: "42px",
                    backgroundColor: "#0da4ce",
                    borderRadius: "0px",
                    "&:hover": {
                      backgroundColor: "#097390",
                      color: "#fff",
                    },
                  }}
                >
                  SIGN UP
                </Button>
              </Box>
            </Box>
          </>
        )}
      </>
      <div id="banner">
        <Banner />
      </div>

      <Info />
      <div id="about">
        <About />{" "}
      </div>
      <div id="why">
        {" "}
        <WhyChoose />{" "}
      </div>
      <Step />
      <div id="how">
        <How />
      </div>
      <div id="step">
        <Steps />
      </div>

      <Join />
      <div id="contact">
        {" "}
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
