import React from "react";
import { Typography, Box, TextField, Button, useMediaQuery,useTheme } from "@mui/material";
import LocalImages from "../../utils/localImages";
// import NormalInput from "../inputField";

const Contact = () => {

    const theme= useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <>
    {!isMobile? (
        <>
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "8%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100% !important",
          }}
        >
          <Box
            sx={{ height: "5px", backgroundColor: "#ffffff", width: "40%" }}
          />
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#4f4f4f",
              width: "20%",
            }}
          >
            CONTACT US
          </Typography>
          <Box
            sx={{ height: "5px", backgroundColor: "#0455bf", width: "40%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "60%",
            margin: "5% 0",
          }}
        >
          <Box>
            <Typography
              sx={{ fontSize: "16px", color: "#4f4f4f", fontWeight: "bold" }}
            >
              Fill in your details and we’ll be in touch.
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "10%",
              }}
            >
              <img src={LocalImages.Call} alt="Call" />
              <Typography
                sx={{ color: "#4f4f4f", fontSize: "16px", fontWeight: "500" }}
              >
                1300 463 46483
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "8%",
              }}
            >
              <img src={LocalImages.Mail} alt="Call" />
              <Typography
                sx={{ color: "#4f4f4f", fontSize: "16px", fontWeight: "500" }}
              >
                support@tradespheregroup.com
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                marginTop: "8%",
              }}
            >
              <img src={LocalImages.Map} alt="Call" />
              <Typography
                sx={{ color: "#4f4f4f", fontSize: "16px", fontWeight: "500" }}
              >
                Australia Wide Services{" "}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              label="Full Name"
              sx={{
                width: "370px",
                marginBottom: "8%",
                borderRadius: "0px",
                [`& fieldset`]: {
                  borderRadius: 0,
                },
              }}
            />

            <TextField
              label="Email Address"
              sx={{
                width: "370px",
                marginBottom: "8%",
                borderRadius: "0px",
                [`& fieldset`]: {
                  borderRadius: 0,
                },
              }}
            />

            <TextField
              label="Subject"
              sx={{
                width: "370px",
                marginBottom: "8%",
                borderRadius: "0px",
                [`& fieldset`]: {
                  borderRadius: 0,
                },
              }}
            />

            <TextField
              label="Message"
              sx={{
                width: "370px",
                marginBottom: "8%",
                height: 200,
                borderRadius: "0px",
                [`& fieldset`]: {
                  borderRadius: 0,
                },

                [`.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input`]: {
                  height: "200px",
                },
              }}
            />

            <Button
              sx={{
                backgroundColor: "#0da4ce",
                width: "370px",
                padding:"10px 0",
                fontWeight:"600",
                color: "#fff",
                marginTop:"6%",
                borderRadius: "0px",
                "&:hover": {
                  backgroundColor: "#097390",
                  color: "#fff",
                },
              }}
            >
              SUBMIT
            </Button>
          </Box>
        </Box>
      </Box></>

    ) : (
        <>
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "8%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100% !important",
          }}
        >
          <Box
            sx={{ height: "5px", backgroundColor: "#ffffff", width: "20%" }}
          />
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#4f4f4f",
              width: "60%",
            }}
          >
            CONTACT US
          </Typography>
          <Box
            sx={{ height: "5px", backgroundColor: "#0455bf", width: "20%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection:"column",
            width: "80%",
            margin: "5% 0",
          }}
        >
          <Box>
            <Typography
              sx={{ fontSize: "14px", color: "#4f4f4f", fontWeight: "bold", width:"327px" }}
            >
              Fill in your details and we’ll be in touch.
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "70%",
                marginTop: "10%",
                
              }}
            >
              <img src={LocalImages.Call} alt="Call" />
              <Typography
                sx={{ color: "#4f4f4f", fontSize: "14px", fontWeight: "500", }}
              >
                1300 463 46483
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                marginTop: "8%",
              }}
            >
              <img src={LocalImages.Mail} alt="Call" />
              <Typography
                sx={{ color: "#4f4f4f", fontSize: "14px", fontWeight: "500" }}
              >
                support@tradespheregroup.com
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "83%",
                marginTop: "8%",
              }}
            >
              <img src={LocalImages.Map} alt="Call" />
              <Typography
                sx={{ color: "#4f4f4f", fontSize: "14px", fontWeight: "500" }}
              >
                Australia Wide Services{" "}
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mt:"20%",
              mr:"15%"
            }}
          >
            <TextField
              label="Full Name"
              sx={{
                width: "325px",
                marginBottom: "8%",
                borderRadius: "0px",
                [`& fieldset`]: {
                  borderRadius: 0,
                },
              }}
            />

            <TextField
              label="Email Address"
              sx={{
                width: "325px",
                marginBottom: "8%",
                borderRadius: "0px",
                [`& fieldset`]: {
                  borderRadius: 0,
                },
              }}
            />

            <TextField
              label="Subject"
              sx={{
                width: "325px",
                marginBottom: "8%",
                borderRadius: "0px",
                [`& fieldset`]: {
                  borderRadius: 0,
                },
              }}
            />

            <TextField
              label="Message"
              sx={{
                width: "325px",
                marginBottom: "8%",
                height: 200,
                borderRadius: "0px",
                [`& fieldset`]: {
                  borderRadius: 0,
                },

                [`.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input`]: {
                  height: "200px",
                },
              }}
            />

            <Button
              sx={{
                backgroundColor: "#0da4ce",
                width: "325px",
                padding:"10px 0",
                fontWeight:"600",
                color: "#fff",
                marginTop:"15%",
                borderRadius: "0px",
                "&:hover": {
                  backgroundColor: "#097390",
                  color: "#fff",
                },
              }}
            >
              SUBMIT
            </Button>
          </Box>
        </Box>
      </Box>
        </>
    )}
      
    </>
  );
};

export default Contact;
