import React from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LocalImages from "../../utils/localImages";

const Join = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <>
      {!isMobile ? (
        <Box
          sx={{
            backgroundImage: `url(${LocalImages.Mask})`,
            height: "650px",
            width: "100% !important",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            textAlign: "center",
            marginTop: "13%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "760px",
              justifyContent: "space-between",
              position: "relative",
              bottom: "8%",
            }}
          >
            <img
              style={{ width: "431px", height: "287px" }}
              src={LocalImages.Boot}
              alt="Boot"
            />
            <img
              style={{ width: "431px", height: "400px" }}
              src={LocalImages.Work}
              alt="Working"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "300px",
              justifyContent: "space-evenly",
              marginTop: "11%",
            }}
          >
            <Typography
              sx={{
                color: "#f6f6f6",
                fontSize: "24px",
                textAlign: "left",
                width: "527px",
                fontWeight: "500",
              }}
            >
              We’re here to leverage technology to build the go-to place for the
              manufacturing industry.
            </Typography>
            <Typography
              sx={{
                color: "#f6f6f6",
                fontSize: "16px",
                textAlign: "left",
                width: "527px",
              }}
            >
              Through connecting tradies with opportunities, manufacturers with
              resources and suppliers with local communities we strive to
              improve outcomes for all.
            </Typography>
            <Button
              sx={{
                backgroundColor: "#ffffff",
                width: "319px",
                color: "#333333",
                borderRadius: "0px",
                "&:hover": {
                  backgroundColor: "#097390",
                  color: "#fff",
                },
              }}
            >
              JOIN INFINITE TRADES
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              height: "745px",
              backgroundImage: `url(${LocalImages.MaskG})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent:"space-evenly",
            }}
          >
            <img
              style={{ width: "327px", height: "367px", padding: "30px 0" }}
              src={LocalImages.Work}
              alt="Working"
            />

            <Typography
              sx={{
                color: "#f6f6f6",
                fontSize: "18px",
                textAlign: "left",
                width: "327px",
                fontWeight: "500",
              }}
            >
              We’re here to leverage technology to build the go-to place for the
              manufacturing industry.
            </Typography>

            <Typography
              sx={{
                color: "#f6f6f6",
                fontSize: "14px",
                textAlign: "left",
                width: "327px",
              }}
            >
              Through connecting tradies with opportunities, manufacturers with
              resources and suppliers with local communities we strive to
              improve outcomes for all.
            </Typography>

            <Button
              sx={{
                backgroundColor: "#ffffff",
                width: "319px",
                color: "#333333",
                borderRadius: "0px",
                "&:hover": {
                  backgroundColor: "#097390",
                  color: "#fff",
                },
              }}
            >
              JOIN INFINITE TRADES
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default Join;
