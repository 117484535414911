import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import LocalImages from "../../utils/localImages";

const Step = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <>
      {!isMobile ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              mt: "3%",
            }}
          >
            <Box>
              <img src={LocalImages.Step1} alt="Step" />
              <Typography
                sx={{ width: "278px", color: "#333333", fontSize: "16px" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
              </Typography>
            </Box>
            <Box>
              <img src={LocalImages.Step2} alt="Step" />
              <Typography
                sx={{ width: "278px", color: "#333333", fontSize: "16px" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
              </Typography>
            </Box>
            <Box>
              <img src={LocalImages.Step3} alt="Step" />
              <Typography
                sx={{ width: "278px", color: "#333333", fontSize: "16px" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "column",
              width: "100%",
              mt: "20%",
            }}
          >
            <Box>
              <img style={{width:"155px", height:"144px"}} src={LocalImages.Step1} alt="Step" />
              <Typography
                sx={{ width: "278px", color: "#333333", fontSize: "14px", padding:"50px" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
              </Typography>
            </Box>
            <Box>
              <img style={{width:"155px", height:"144px"}} src={LocalImages.Step2} alt="Step" />
              <Typography
                sx={{ width: "278px", color: "#333333", fontSize: "14px", padding:"50px" }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
              </Typography>
            </Box>
            <Box  sx={{width:"100%"}}>
              <img style={{width:"155px", height:"144px"}} src={LocalImages.Step3} alt="Step" />
              <Typography
                sx={{ width: "268px", color: "#333333", fontSize: "14px", padding:"50px",  }}
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Step;
