import React from "react";

import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LocalImages from "../../utils/localImages";

const Banner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));

  return (
    <>
      {!isMobile ? (
        <Box
          sx={{
            backgroundImage: `url(${LocalImages.LandingBackground})`,
            height: "650px",
            width: "100% !important",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            textAlign: "center",
          }}
        >
          <Box sx={{ paddingTop: "206px" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "40px",
                  fontWeight: "600",
                }}
              >
                Create Infinite Industry Connections
              </Typography>
              <Typography
                sx={{
                  color: "rgba(255, 255, 255, 0.8)",
                  fontSize: "21px",
                  width: "876px",
                }}
              >
                We connect Skilled Tradespeople with Industrial and
                Manufacturing Facilities and OEM's requiring Maintenance and
                Project Services.
              </Typography>
            </Box>

            <Box>
              <Button
                sx={{
                  width: "540px",
                  height: "63px",
                  backgroundColor: "#0da4ce",
                  padding: "23px 38px 23px 37px",
                  margin: "40px 0 0 24px",
                  color: "#fff",
                  fontSize: "13px",
                  borderRadius: "0px",
                  "&:hover": {
                    backgroundColor: "#097390",
                    color: "#fff",
                  },
                }}
              >
                I need Skilled Trade Services for my Facility or Machinery
              </Button>
              <Button
                sx={{
                  width: "540px",
                  height: "63px",
                  backgroundColor: "#f2f2f2",
                  padding: "23px 38px 23px 37px",
                  margin: "40px 0 0 24px",
                  color: "#4f4f4f",
                  fontSize: "13px",
                  borderRadius: "0px",
                  "&:hover": {
                    backgroundColor: "#097390",
                    color: "#fff",
                  },
                }}
              >
                I'm a Trade Service Provider looking for opportunities
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              backgroundImage: `url(${LocalImages.Landing})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100%",
              height: "560px",
              width: "100%",
            }}
          >
            <Box sx={{ padding: "100px 30px",  }}>
              <Box
                sx={{
                  width: "327px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "2%"
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "21px",
                    fontWeight: "600",
                  }}
                >
                  Create Infinite Industry Connections
                </Typography>

                <Typography
                  sx={{
                    color: "rgba(255, 255, 255, 0.8)",
                    fontSize: "14px",
                    width: "327px",
                    marginTop: "30px",
                  }}
                >
                  We connect Skilled Tradespeople with Industrial and
                  Manufacturing Facilities and OEM's requiring Maintenance and
                  Project Services.
                </Typography>
                <Box>
                  <Button
                    sx={{
                      width: "327px",
                      height: "24px",
                      backgroundColor: "#0da4ce",
                      padding: "21px 40px",
                      margin: "40px 0",
                      color: "#fff",
                      fontSize: "13px",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "#097390",
                        color: "#fff",
                      },
                    }}
                  >
                    I need Skilled Trade Services
                  </Button>
                  <Button
                    sx={{
                      width: "327px",
                      height: "24px",
                      backgroundColor: "#f2f2f2",
                      padding: "21px 40px",
                      margin: "",
                      color: "#4f4f4f",
                      fontSize: "13px",
                      borderRadius: "0px",
                      "&:hover": {
                        backgroundColor: "#097390",
                        color: "#fff",
                      },
                    }}
                  >
                    I’M A TRADE SERVICE PROVIDER
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Banner;
