import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const Info = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <>
      {!isMobile ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <Typography
            sx={{ color: "#333333", fontSize: "40px", fontWeight: "600" }}
          >
            100+
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#4f4f4f",
              }}
            >
              PROJECTS COMPLETED
            </Typography>
          </Typography>

          <Typography
            sx={{ color: "#333333", fontSize: "40px", fontWeight: "600" }}
          >
            5 mins
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#4f4f4f",
              }}
            >
              TIME IT TAKES TO FILL A JOB
            </Typography>
          </Typography>

          <Typography
            sx={{ color: "#333333", fontSize: "40px", fontWeight: "600" }}
          >
            $123
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#4f4f4f",
              }}
            >
              REVENUE GENERATED
            </Typography>
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Typography
              sx={{ color: "#333333", fontSize: "24px", fontWeight: "600", marginTop:"8%", }}
            >
              100+
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#4f4f4f",
                  marginTop:"3%",
                }}
              >
                PROJECTS COMPLETED
              </Typography>
            </Typography>

            <Typography
              sx={{ color: "#333333", fontSize: "24px", fontWeight: "600", marginTop:"8%", }}
            >
              5 mins
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#4f4f4f",
                  marginTop:"3%",
                }}
              >
                TIME IT TAKES TO FILL A JOB
              </Typography>
            </Typography>

            <Typography
              sx={{ color: "#333333", fontSize: "24px", fontWeight: "600", marginTop:"8%", }}
            >
              $123
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#4f4f4f",
                  marginTop:"3%",
                }}
              >
                REVENUE GENERATED
              </Typography>
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

export default Info;
