import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import LocalImages from "../../utils/localImages";

const Steps = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(600));
  return (
    <>
      {!isMobile ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
              mt: "3%",
            }}
          >
            <Box>
              <img src={LocalImages.Step4} alt="Step" />
              <Typography
                sx={{ fontSize: "16px", color: "#4f4f4f", fontWeight: "600" }}
              >
                Step 1
              </Typography>
              <Typography
                sx={{ width: "210px", color: "#333333", fontSize: "16px" }}
              >
                List your job details using our tools.
              </Typography>
            </Box>
            <Box>
              {/* <img src={LocalImages.Step1} alt="Step" /> */}
              <img src={LocalImages.Step2} alt="Step" />
              <Typography
                sx={{ fontSize: "16px", color: "#4f4f4f", fontWeight: "600" }}
              >
                Step 2
              </Typography>
              <Typography
                sx={{ width: "210px", color: "#333333", fontSize: "16px" }}
              >
                Service providers respond to your request.
              </Typography>
            </Box>
            <Box>
              {/* <img src={LocalImages.Step2} alt="Step" /> */}
              <img src={LocalImages.Step3} alt="Step" />
              <Typography
                sx={{ fontSize: "16px", color: "#4f4f4f", fontWeight: "600" }}
              >
                Step 3
              </Typography>
              <Typography
                sx={{ width: "210px", color: "#333333", fontSize: "16px" }}
              >
                You select the best fit for your needs.
              </Typography>
            </Box>
            <Box>
              <img src={LocalImages.Step1} alt="Step" />
              <Typography
                sx={{ fontSize: "16px", color: "#4f4f4f", fontWeight: "600" }}
              >
                Step 4
              </Typography>
              <Typography
                sx={{ width: "215px", color: "#333333", fontSize: "16px" }}
              >
                Your trade of choice provides the requested services.
              </Typography>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection:"column",
              width: "100%",
              mt: "8%",
            }}
          >
            <Box>
              <img style={{width:"155px", height:"144px"}} src={LocalImages.Step4} alt="Step" />
              <Typography
                sx={{ fontSize: "16px", color: "#4f4f4f", fontWeight: "600", mt:"2%" }}
              >
                Step 1
              </Typography>
              <Typography
                sx={{ width: "249px", color: "#333333", fontSize: "14px", padding:"20px 70px" }}
              >
                List your job details using our tools.
              </Typography>
            </Box>
            <Box>
              <img style={{width:"155px", height:"144px"}} src={LocalImages.Step1} alt="Step" />
              <Typography
                sx={{ fontSize: "16px", color: "#4f4f4f", fontWeight: "600", mt:"2%" }}
              >
                Step 2
              </Typography>
              <Typography
                sx={{ width: "249px", color: "#333333", fontSize: "14px", padding:"20px 70px" }}
              >
                Service providers respond to your request.
              </Typography>
            </Box>
            <Box>
              <img style={{width:"155px", height:"144px"}} src={LocalImages.Step2} alt="Step" />
              <Typography
                sx={{ fontSize: "16px", color: "#4f4f4f", fontWeight: "600",mt:"2%" }}
              >
                Step 3
              </Typography>
              <Typography
                sx={{ width: "249px", color: "#333333", fontSize: "14px", padding:"20px 70px" }}
              >
                You select the best fit for your needs.
              </Typography>
            </Box>
            <Box>
              <img style={{width:"155px", height:"144px"}} src={LocalImages.Step3} alt="Step" />
              <Typography
                sx={{ fontSize: "16px", color: "#4f4f4f", fontWeight: "600",mt:"2%" }}
              >
                Step 4
              </Typography>
              <Typography
                sx={{ width: "249px", color: "#333333", fontSize: "14px", padding:"20px 70px" }}
              >
                Your trade of choice provides the requested services.
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Steps;
